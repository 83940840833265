<template>
  <div class="concrete" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div id="concrete_map">
      <div class="tabs">
         <div class="ele" v-for="(item,index) in tabs" :key="index" :class="{active: item.val == tab_avtive}" @click="changeTab(item)">
          <div v-if="item.val == 0 && tab_avtive == 0 && diyTime && diyTime.length > 0">
            <span>{{ diyTime[0] }}</span>
            <span>{{ diyTime[1] }}</span>
          </div>
          <div v-else>{{item.name}}</div>
        </div>
      </div>
    </div>
    <el-drawer
      title="自定义时间区间"
      :visible.sync="drawer"
      direction="ltr"
      :size="420">
      <el-date-picker
        v-model="diyTime"
        type="daterange"
        range-separator="到"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="selectTime"
        :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" @click="selectTime(diyTime)">确定</el-button>
    </el-drawer>
    <div class="card-box" :class="{'open-data':open}" v-if="dataList && dataList.length > 0">
      <div class="ele">
        <card class="card">
          <div class="title">销量统计（近一月）</div>
          <div class="scroll-box">
            <vue-scroll>
              <div class="list-box" v-if="dataList.length > 0">
                <div class="list" v-for="(item,index) in dataList" :key="index">
                  <div class="tag">{{ index+1 }}</div>
                  <div class="list-val">
                    <div class="key">{{ item.name }} 累计销量</div>
                    <div class="val">{{ item.weight || 0 }}吨</div>
                  </div>
                </div>
              </div>
              <div class="no-list" v-else>暂无数据</div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="ele">
        <card class="card">
          <div class="title">销量额统计（近一月）</div>
          <div class="scroll-box">
            <vue-scroll>
              <div class="list-box" v-if="dataList.length > 0">
                <div class="list" v-for="(item,index) in dataList" :key="index">
                  <div class="tag">{{ index+1 }}</div>
                  <div class="list-val">
                    <div class="key">{{ item.name }} 累计销量额</div>
                    <div class="val">{{ item.sum_money || 0 }}元</div>
                  </div>
                </div>
              </div>
              <div class="no-list" v-else>暂无数据</div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="ele">
        <card class="card">
          <div class="title">车次统计（近一月）</div>
          <div class="scroll-box">
            <vue-scroll>
              <div class="list-box" v-if="dataList.length > 0">
                <div class="list" v-for="(item,index) in dataList" :key="index">
                  <div class="tag">{{ index+1 }}</div>
                  <div class="list-val">
                    <div class="key">{{ item.name }} 累计车次</div>
                    <div class="val">{{ item.car_num || 0 }}辆</div>
                  </div>
                </div>
              </div>
              <div class="no-list" v-else>暂无数据</div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="open" @click="open = !open">
        <div class="img-box" :class="{rotate:open}">
          <img src="@/assets/image/open.png">
        </div>
      </div>
    </div>
    <div class="price">
      <transition name="slide-fade">
        <card class="card" style="margin-bottom:12px" v-if="showHistory">
          <div class="card-tabs">
            <div class="title">历史报价</div>
            <i class="el-icon-close" @click="showHistory = false"></i>
          </div>
          <div class="list-title">
            <div class="ele">价格</div>
            <div class="item">用户</div>
            <div class="ele">日期</div>
          </div>
          <div class="list-box" v-if="historyList.length > 0">
            <div class="list" v-for="(item,index) in historyList" :key="index">
              <div class="ele">
                <span>{{ item.goods_price }}</span>
              </div>
              <div class="item" :title="item.nickname">
                <div class="headimg">
                  <img v-if="item.avatar" :src="item.avatar" alt="">
                  <i v-else class="el-icon-s-custom"></i>
                </div>
              </div>
              <div class="ele">
                <span class="time">{{ item.created_at }}</span>
              </div>
            </div>
          </div>
          <div class="no-list" v-else>暂无商品</div>
        </card>
      </transition>
      <transition name="slide-fade">
        <card class="card" v-if="showPrice">
          <div class="card-tabs">
            <div class="title" v-if="priceType == 1">矿山报价</div>
            <div class="title" v-if="priceType == 2">商砼报价</div>
            <div class="tab" v-if="priceType == 2">
              <div class="ele" v-for="(item,index) in price_tab" :key="index" :class="{active:index == price_type_active}" @click="price_type_active = index">{{ item }}</div>
            </div>
          </div>
          <div class="list-title">
            <div class="ele">商品名称</div>
            <div class="ele">价格</div>
          </div>
          <div class="list-box" v-if="priceList.length > 0">
            <div class="list" 
              v-for="(item,index) in priceList" 
              :key="index" 
              :class="{hover:priceType == 2,active:item == price_active}"
              @click="getHistory(item)"
            >
              <div class="ele">
                <span>{{ item.name }}</span>
              </div>
              <div class="ele list-price" v-if="priceType == 2">
                <span class="status" v-if="price_type_active == 0">{{ item.new_price ? item.new_price : '-' }}</span>
                <span class="status" v-if="price_type_active == 1">{{ item.max_price }}</span>
                <span class="status" v-if="price_type_active == 2">{{ item.min_price }}</span>
                <span class="status" v-if="price_type_active == 3">{{ item.price }}</span>
              </div>
              <div class="ele list-price" v-else>
                <span class="status">{{ item.goods_unit_price }}{{ item.company }}</span>
              </div>
            </div>
          </div>
          <div class="no-list" v-else>暂无商品</div>
        </card>
      </transition>
    </div>
    <transition name="slide-fade">
      <div class="driver" v-if="showDriver">
        <card class="card">
          <div class="title">车辆详情</div>
          <div class="list-driver">
            <div class="list-title">车辆号</div>
            <div class="list-box">
              <div class="list" v-for="(item,index) in driverList" :key="index" @click="goDriver(item)">{{ item.car_number }}</div>
            </div>
          </div>
        </card>
      </div>
    </transition>
    <info-window ref="infoWindow" @openPrice="openPrice" @openDeriver="openDeriver" @goDetails="goDetails"></info-window>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
import icons from '../../assets/js/map_icon.js';
export default {
  name:"",
  data(){
    return {
      drawer:false,
      diyTime:[],
      pickerOptions:{
        disabledDate(time){
          return time.getTime() > Date.now();
        }
      },
      fullscreenLoading:false,
      id:null,
      tabs:[{name:'自定义',val:0},{name:'三天',val:3},{name:'一周',val:7},{name:'半个月',val:15},{name:'一个月',val:30}],
      tab_avtive:30,
      dataList:null,
      open:false,
      showDriver:false,
      driverList:[],
      showPrice:false,
      price_tab:['最新价','最高价','最低价','平均价'],
      price_type_active:0,
      priceId:null,
      priceType:1,
      priceList:[],
      price_active:null,
      showHistory: false,
      historyList:[],
    }
  },
  created(){
    this.id = this.$route.params.id;
    lazyAMapApiLoaderInstance.load().then(()=>{
      this.map = new AMap.Map("concrete_map", {
        expandZoomRange: true,
        zooms: [5, 18],
        zoom: 10,
        mapStyle: "amap://styles/ddf1c576d07f43d8ad696a72ca5543a5",
      });
      AMap.plugin(['AMap.Scale',], ()=>{this.map.addControl(new AMap.Scale())});
      this.getconcreteDetails(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1]);
      this.getData();
      this.map.on('click',(ev)=>{
        this.showPrice = false;
        this.showHistory = false;
        this.showDriver = false;
      })
    })
  },
  methods:{
    changeTab(item){
      this.tab_avtive = item.val;
      if(item.val != 0){
        if(this.map){
          this.map.remove(this.overlayGroup);
          this.map.remove(this.map.getAllOverlays('InfoWindow'));
          this.showPrice = false;
          this.showHistory = false;
          this.showDriver = false;
          this.getconcreteDetails(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1]);
        }
      }else{
        this.drawer = true;
      }
    },
    selectTime(time){
      if(time && time.length > 0){
        this.drawer = false;
        this.map.remove(this.overlayGroup);
        this.map.remove(this.map.getAllOverlays('InfoWindow'));
        this.showPrice = false;
        this.showHistory = false;
        this.showDriver = false;
        this.getconcreteDetails(`${time[0]} 00:00:00`,`${time[1]} 23:59:59`);
      }
    },
    setMine(center,title){
      let _this = this;
      let marker = new AMap.Marker({
        map: _this.map,
        position: center,
        content:_this.returnConcreteContent(title),
        offset: new AMap.Pixel(0, 13),
        anchor:'bottom-center',
        zIndex:1000
      });
    },
    returnConcreteContent(title){
      let content = '';
      content += '<div class="mine-marker">'
      content += '  <div class="windows">'
      content += '    <div class="bar bar-1"></div>'
      content += '    <div class="bar bar-2"></div>'
      content += '    <div class="bar bar-3"></div>'
      content += '    <div class="bar bar-4"></div>'
      content += '    <div class="title">'+ title +'</div>'
      content += '    <div class="option id_'+ this.id +'" id="id_'+ this.id +'"><span class="btn">报价</span></div>'
      content += '  </div>'
      content += '  <img src="'+ icons['type_1_1'] +'"/>'
      content += '  <img src="'+ icons.mine_center +'"/>'
      content += '</div>'
      return content;
    },
    getconcreteDetails(start,end){
      this.fullscreenLoading = true;
      let _this = this;
      this.overlayGroup = new AMap.OverlayGroup();
      this.$api.getConcreteDetail({
        commercial_concrete_id: this.id,
        start_time:start,
        end_time:end
      }).then(res => {
        let center = [res.data.commercial_concrete_info.lon,res.data.commercial_concrete_info.lat];
        this.setMine(center,res.data.commercial_concrete_info.name);
        this.$nextTick(() => {
          setTimeout(() => {
            let item = document.querySelector('#id_' + this.id);
            item.addEventListener('click',function(){
              _this.openConPrice();
            })
          },500)
        })
        res.data.factory_info.lists.forEach((ele,index) => {
           var circleMarker = new AMap.CircleMarker({
            center:[ele.lon,ele.lat],
            radius:4,
            strokeColor:'#54FFD9',
            fillColor:'#54FFD9',
            zIndex:100,
          })
          this.overlayGroup.add(circleMarker);
          let marker = new AMap.Marker({
            position: [ele.lon,ele.lat],
            icon: icons[`type_3_${ele.level ? (ele.level-1) : 0}`],
            offset: new AMap.Pixel(0, -8),
            anchor:'bottom-center',
            zIndex:100
          });
          this.overlayGroup.add(marker)
          let line = new AMap.Polyline({
            path: [center,[ele.lon,ele.lat]],
            strokeColor: "#54FFD9",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            strokeStyle: "dashed",
            strokeDasharray: [4, 8],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
          })
          this.overlayGroup.add(line);
          let infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -60),
            isCustom:true,
            closeWhenClickMap:true,
          });
          marker.on('click', (e)=>{
            _this.showPrice = false;
            this.showHistory = false;
            this.showDriver = false;
            _this.$refs.infoWindow.initialize({
              type:1,
              title:ele.name,
              color:'#E9310F',
              showPrice: true,
              showDeatils:true,
              showDisable: false,
              showSecond:true,
              ele
            })
            infoWindow.setContent(this.$refs.infoWindow.$el);
            infoWindow.open(_this.map, e.target.getPosition());
          });
        });
        this.map.add(this.overlayGroup);
        if(res.data.factory_info.lists.length > 0){
          this.map.setFitView();
        }else{
          this.map.setCenter(center);
        }
      }).finally(() => {
        this.fullscreenLoading = false;
      })
    },
    getData(){
      this.$api.mineconSaleRank({
        type: 2,
        type_id: this.id,
        page: 1,
        size: 1000,
        start_time:this.getDateStr(30)[0],
        end_time:this.getDateStr(30)[1]
      }).then(res => {
        this.dataList = res.data.lists;
      })
    },
    openPrice(info){
      this.priceType = 1;
      this.$api.goodsList({
        type: 1,
        type_id: info.ele.id,
        page: 1,
        size: 1000
      }).then(res => {
        if(res.code == 1){
          this.showDriver = false;
          this.showPrice = true;
          this.priceList = [...res.data.lists]
        }else{
          this.$notify.error({
            title: '错误',
            message: res.msg,
            offset: 100
          });
        }
      }).catch(err =>{
        this.$notify.error({
          title: '错误',
          message: '接口失败，请重试',
          offset: 100
        });
      })
    },
    openConPrice(){
      this.priceType = 2;
      this.priceId = this.id;
      this.$api.concreteOffer({
        commercial_concrete_id: this.id,
        page: 1,
        size: 1000
      }).then(res => {
        if(res.code == 1){
          this.price_type_active = 0;
          this.showDriver = false;
          this.showPrice = true;
          this.priceList = [...res.data.lists]
        }else{
          this.$notify.error({
            title: '错误',
            message: res.msg,
            offset: 100
          });
        }
      }).catch(err =>{
        this.$notify.error({
          title: '错误',
          message: '接口失败，请重试',
          offset: 100
        });
      })
    },
    getHistory(info){
      if(this.priceType != 2) return;
      this.price_active = info;
      this.$api.concreteOfferHistory({
        commercial_concrete_id: this.priceId,
        receiving_goods_as_id: info.receiving_goods_as_id
      }).then(res => {
        this.showHistory = true;
        this.historyList = res.data.lists
      }).catch(err =>{
        this.$notify.error({
          title: '错误',
          message: '接口失败，请重试',
          offset: 100
        });
      })
    },
    openDeriver(info){
      this.$api.getCarList({
        factory_id: info.ele.id,
        commercial_concrete_id:this.id,
        page:1,
        size:1000
      }).then(res=>{
        if(res.code == 1){
          this.driverList = [...res.data.lists]
          if(this.showPrice){
            this.showPrice = false;
            this.showHistory = false;
            setTimeout(()=>{
              this.showDriver = !this.showDriver;
            },200)
          }else{
            this.showDriver = !this.showDriver;
          }
        }
      })
    },
    goDetails(info){
      this.$router.push({
        name:'mapMine',
        params: { id: info.ele.id }
      })
    },
    goDriver(info){
      this.$router.push({
        name:'mapDirver',
        params: { id:info.car_number }
      })
    },
    getDateStr(val){
      var now = new Date().getTime();
      var half = now - 1000 * 60 * 60 * 24 * val;
      return [`${this.returnDate(half)} 00:00:00`,`${this.returnDate(now)} 23:59:59`]
    },
    returnDate(str){
      let _date = new Date(str)
      let year = _date.getFullYear();
      let month = _date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      let day = _date.getDate();
      day = day < 10 ? '0' + day : day;
      return `${year}-${month}-${day}`
    },
  }
}
</script>
<style scoped lang='less'>
.concrete{
  width: 100%;
  overflow: hidden;
}
#concrete_map{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tabs{
  box-sizing: border-box;
  width: 550px;
  position: absolute;
  top: 22px;
  left: 30px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ele{
    width: 92px;
    height: 38px;
    background: rgba(4, 58, 112, 0.5);
    border-radius: 3px;
    border: 1px solid #00AEFF;
    box-shadow:0 0 8px 0 #00AEFF inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #7AC5E8;
    font-weight: 400;
    cursor: pointer;
    div{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    span{
      font-size: 12px;
    }
  }
  .active{
    background: rgba(0, 147, 167, 0.5);
    border: 1px solid #00BFEF;
    box-shadow:0 0 8px 0 #0093A7 inset;
    color: #00F7FF;
  }
}

.price{
  width: 430px;
  position: fixed;
  right: 40px;
  bottom: 80px;
  z-index: 99999;
  box-sizing: border-box;
  .card-tabs{
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 10px;
    .title{
      font-size: 22px;
      color: #B8E5FA;
      font-weight: 500;
      flex: 1;
      text-align: center;
    }
    .tab{
      width: 280px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ele{
        width: 60px;
        height: 38px;
        background: #043A70;
        border: 1px solid #00AEFF;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #7AC5E8;
        font-weight: 500;
        cursor: pointer;
      }
      .active{
        background: rgba(0, 147, 167, 0.3);
        color: #00F7FF;
        border: 1px solid #00BFEF;
        box-shadow:0 0 8px 0 #0093A7 inset;
      }
    }
    i{
      font-size: 22px;
      color: #B8E5FA;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .list-title{
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: #0172A5;
    .ele{
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
    }
    .item{
      width: 15%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 500;
    }
  }
  .list-box{
    padding: 10px;
    min-height: 100px;
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .list{
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding: 10px 0;
      display: flex;
      .ele{
        flex: 1;
        padding: 0 10px;
        height: 100%;
        color: #59BAF2;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span{
          max-width: 100%;
          font-size: 20px;
          color: #59BAF2;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time{
          font-size: 16px;
        }
        .status{
          position: relative;
          padding-right: 24px;
          margin-right: -24px;
        }
        .up{
          &::after{
            content: '';
            display: block;
            width: 14px;
            height: 22px;
            background: url('../../assets/image/home-price-up.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
        .down{
          &::after{
            content: '';
            display: block;
            width: 14px;
            height: 22px;
            background: url('../../assets/image/homt-price-down.png') no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }
      .item{
        width: 15%;
        height: 100%;
        display: flex;
        align-items: center;
        .headimg{
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
          background-color: rgba(0, 147, 167, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
            height: 100%;
          }
          i{
            color: #fff;
            font-size: 16px;
          }
        }
      }
    }
    .hover{
      cursor: pointer;
      &:hover{
        background: #043A70;
      }
    }
    .active{
      background: #043A70;
    }
  }
  .no-list{
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 22px;
    color: #59BAF2;
    font-weight: 500;
  }
}

.driver{
  width: 200px;
  position: fixed;
  right: 40px;
  bottom: 70px;
  z-index: 99999;
  .title{
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #B8E5FA;
    font-weight: 500;
  }
  .list-driver{
    width: 100%;
    .list-title{
      width: 100%;
      height: 50px;
      background: #0172A5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: 400;
    }
    .list-box{
      box-sizing: border-box;
      width: 100%;
      min-height: 100px;
      max-height: 320px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
      .list{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 400;
        cursor: pointer;
        &:hover{
          color: #54FFD9;
        }
      }
    }
  }
}
.card-box{
  box-sizing: border-box;
  width: 100%;
  height: 340px;
  padding: 0 10px 40px;
  position: fixed;
  bottom: -290px;
  left: 0;
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ele{
    box-sizing: border-box;
    width: 33.3%;
    min-width: 300px;
    height: 100%;
    padding: 0 30px;
    .card{
      padding: 18px 5px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .title{
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: #B8E5FA;
        font-weight: 400;
        line-height: 28px;
      }
      .scroll-box{
        height: 230px;
        margin-top: 14px;
        .list-box{
          width: 100%;
          padding: 0 10px;
          box-sizing: border-box;
        }
        .list{
          width: 100%;
          height: 39px;
          margin-bottom: 8px;
          background: rgba(83, 198, 222, 0.11);
          display: flex;
          align-items: center;
          .tag{
            width: 50px;
            height: 100%;
            background: #747980;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .list-val{
            flex: 1;
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0 20px 0 12px;
            .key{
              flex: 1;
              height: 100%;
              line-height: 40px;
              font-size: 18px;
              color: #FFFFFF;
              font-weight: 400;
              margin-right: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis
            }
            .val{
              font-size: 18px;
              color: #FFFFFF;
              font-weight: 400;
              line-height: 40px;
            }
          }
          &:nth-child(1){
            .tag{
              background: #E04236;
            }
          }
          &:nth-child(2){
            .tag{
              background: #CAC401;
            }
          }
          &:nth-child(3){
            .tag{
              background: #1C7EF6;
            }
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .no-list{
        width: 100%;
        height: 230px;
        line-height: 230px;
        text-align: center;
        font-size: 22px;
        color: #59BAF2;
        font-weight: 400;
      }
    }
  }
  .open{
    width: 60px;
    height: 50px;
    background: rgba(25, 49, 86, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: -60px;
    .img-box{
      width: 30px;
      height: 30px;
      transition: All .5s ease;
    }
    img{
      width: 100%;
      height: 100%;
      animation: jump 1s linear infinite;
    }
    .rotate{
      transform: rotate(180deg);
    }
    @keyframes jump {
      0% {
        transform: translateY(0) ;
      }
      25% {
          transform: translateY(3px);
      }
      50% {
          transform: translateY(6px) scale(1.1, 0.9);
      }
      75% {
          ransform: translateY(3px) ;
      }
      100% {
          transform: translateY(0) ;
      }
    }
  }
}

.open-data{
  bottom: 0;
  transition: all .5s ease;
}


.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(40px);
  opacity: 0;
}
</style>
<style lang="less">
.mine-marker{
  display: flex;
  flex-direction: column;
  align-items: center;
  .windows{
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #11DB33;
    background: #12223B;
    box-shadow:0 0 8px 0 #11DB33 inset;
    margin-bottom: 1px;
    position: relative;
    .title{
      white-space: nowrap;
      font-size: 18px;
      color: #00A0E9;
      font-weight: 400;
      padding: 0 12px;
    }
    .bar{
      width: 10px;
      height: 8px;
      position: absolute;
    }
    .option{
      height: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      position: relative;
      &::before{
        content: '';
        display: block;
        width: 1px;
        height: 18px;
        background: rgba(255, 255, 255, 0.7);
        position: absolute;
        left: 0;
      }
      span{
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 400;
        white-space:nowrap;
      }
      .btns{
        color: #767676;
      }
    }
    .bar-1{
      left: -1px;
      top: -1px;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #EBE700;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after{
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background: #EBE700;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .bar-2{
      right: -1px;
      top: -1px;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #EBE700;
        position: absolute;
        top: 0;
        right: 0;
      }
      &::after{
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background: #EBE700;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .bar-3{
      right: -1px;
      bottom: -1px;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #EBE700;
        position: absolute;
        bottom: 0;
        right: 0;
      }
      &::after{
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background: #EBE700;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .bar-4{
      left: -1px;
      bottom: -1px;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: #EBE700;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      &::after{
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        background: #EBE700;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.el-drawer__body{
  padding: 20px;
  .el-date-editor{
    min-width: 380px;
  }
  .el-button{
    width: 100px;
    height: 40px;
    margin-top: 14px;
    color: #fff;
  }
}
</style>